/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */





html, body { height: 100%; margin: 0; }


.navbar{
  background-color: #d7dbde!important;
}

.footer{
  background-color: #d7dbde!important;
}

.jumbotron{
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
}

.jumbotron-trainer{
  background-image: url(assets/trainer.png);
}

.jumbotron-uploader{
  background-image: url(assets/uploader.png);
}

.jumbotron-corrector-translater{
  background-image: url(assets/corrector-translater.png);
}



@media screen and (min-width: 767.98px) {

  .scroll-block {
    margin-top: 35px;
  }

  .scroll-list {
    max-height: 700px;
    overflow-y: scroll;
    border-top: 1px solid rgba(0,0,0,.125);
    border-bottom: 1px solid rgba(0,0,0,.125);
  }
}



